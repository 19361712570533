import { firebaseApp } from '@/main.js'
import { getFirestore, collection, query, where, doc, orderBy, getDocs, addDoc, setDoc, deleteDoc } from 'firebase/firestore'
import { reactive, toRefs, readonly } from 'vue'

const db = getFirestore(firebaseApp)

const state = reactive({
  currentNotifications: [],
  nextNotifications: [],
  previousNotifications: []
})

export default function () {
  const resetStates = () => {
    state.currentNotifications = []
    state.nextNotifications = []
    state.previousNotifications = []
  }

  const fetchCurrentNotifications = async () => {
    try {
      if (!state.currentNotifications.length) {
        const notificationsCollectionRef = collection(db, 'NOTIFICATIONS')
        const notificationsQuery = query(notificationsCollectionRef, where('end', '>=', new Date()), orderBy('end', 'desc'))
        const querySnap = await getDocs(notificationsQuery)

        state.currentNotifications = querySnap.docs
          .map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            if (new Date() > new Date(start)) return { ...data, id: doc.id, start, end }
          })
          .filter(n => n)
      }
    } catch (e) {
      console.error('Error fetching notifications: ', e)
    }
  }

  const fetchPreviousNotifications = async () => {
    try {
      if (!state.previousNotifications.length) {
        const notificationsCollectionRef = collection(db, 'NOTIFICATIONS')
        const notificationsQuery = query(notificationsCollectionRef, where('start', '<=', new Date()), orderBy('start', 'desc'))
        const querySnap = await getDocs(notificationsQuery)

        state.previousNotifications = querySnap.docs
          .map(doc => {
            const data = doc.data()
            const start = data.start.toMillis()
            const end = data.end.toMillis()
            if (new Date() > new Date(end)) return { ...data, id: doc.id, start, end }
          })
          .filter(n => n)
      }
    } catch (e) {
      console.error('Error fetching previous notifications: ', e)
    }
  }

  const fetchNextNotifications = async () => {
    try {
      if (!state.nextNotifications.length) {
        const notificationsCollectionRef = collection(db, 'NOTIFICATIONS')
        const notificationsQuery = query(notificationsCollectionRef, where('start', '>', new Date()), orderBy('start', 'asc'))
        const querySnap = await getDocs(notificationsQuery)

        state.nextNotifications = querySnap.docs.map(doc => {
          const data = doc.data()
          const start = data.start.toMillis()
          const end = data.end.toMillis()
          return { ...data, id: doc.id, start, end }
        })
      }
    } catch (e) {
      console.error('Error fetching next notifications: ', e)
    }
  }

  const updateNotification = async ({ id, ...notification }) => {
    try {
      const start = new Date(notification.start)
      const end = new Date(notification.end)
      const documentRef = doc(db, `NOTIFICATIONS/${id}`)
      await setDoc(documentRef, { ...notification, start, end })
      resetStates()
    } catch (e) {
      console.error('Error updating notification: ', e)
    }
  }

  const addNotification = async notification => {
    try {
      const start = new Date(notification.start)
      const end = new Date(notification.end)
      const notificationsCollectionRef = collection(db, 'NOTIFICATIONS')
      await addDoc(notificationsCollectionRef, { ...notification, start, end })
      resetStates()
    } catch (e) {
      console.error('Error adding notification: ', e)
    }
  }

  const deleteNotification = async ({ id }) => {
    try {
      const documentRef = doc(db, `NOTIFICATIONS/${id}`)
      await deleteDoc(documentRef)
      resetStates()
    } catch (e) {
      console.error('Error deleting notification: ', e)
    }
  }

  return {
    ...toRefs(readonly(state)),
    fetchCurrentNotifications,
    fetchPreviousNotifications,
    fetchNextNotifications,
    updateNotification,
    addNotification,
    deleteNotification
  }
}
